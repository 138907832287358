import React from "react"
import { Col, Row } from "react-bootstrap"
import { useGlobalState } from "../contexts/global_state"
import "./checkout.scss"
import bin from "../images/checkout/bin.png"
import { useEffect, useState } from "react"
import { navigate } from "gatsby"
import CheckoutForm from "../components/checkoutForm"
import NavigationBar from "../components/navigation_bar"
import { PaymentForm, PaymentReceipt } from "../interfaces/payment"
import { addPaymentReciept } from "../utils/fire"
import { useForm } from "react-hook-form"
import SEO from '../components/seo';
export default function Checkout() {
  const {
    register,
    formState: { isValid },
  } = useForm({
    mode: "onChange"
  })
  const [state, dispatch] = useGlobalState()
  const total = state.cart.reduce((accu, curr) => accu + curr.price, 0)
  const [clientToken, setClientToken] = useState<string | null>()
  const [paymentProcessing, setPaymentProcessing] = useState(false)
  async function setToken() {
    const response = await fetch(
      "https://us-central1-tapl-solar.cloudfunctions.net/braintreeFunction/getToken"
    )
    const responseJSON = await response.json()
    const clientToken2 = responseJSON.clientToken
    if (clientToken2) setClientToken(clientToken2)
  }
  useEffect(() => {
    setToken()
  }, [])
  useEffect(() => {
    if (state.cart.length === 0 && !paymentProcessing) {
      navigate("/store")
      alert("Cart Empty!")
    }
  }, [state.cart.length])
  function onSuccess() {
    setPaymentProcessing(true)
    const paymentForm: PaymentForm = {
      name: "FORM NOT CONNECTED",
      address: "FORM NOT CONNECTED",
    }
    const paymentReceipt: PaymentReceipt = {
      paymentForm,
      amountPaid: total,
      date:new Date().toISOString(),
      cart: state.cart,
    }
    addPaymentReciept(paymentReceipt)
      .then(() => {
        dispatch({ type: "CLEAR_CART", payload: null })
        navigate("/payment-success")
      })
      .catch(e => {
        alert(
          "Payment Successful but error writing to database. Contact Admin immediately! " +
            e
        )
        setPaymentProcessing(false)
      })
  }

  return (
    <div>
      <SEO title="Checkout"/>
      <NavigationBar current="/store" />
      <div className="checkout container-fluid p-md-5">
        <Row className="flex-wrap">
          <Col className="orderSummary m-4">
            <header className="header">Order summery</header>
            <div className="card">
              <div className="cartItems">
                {state.cart.map(item => (
                  <div className="cartItem">
                    <img
                      className="image"
                      src={item.imageURL}
                      alt={item.name}
                      width={110}
                    />
                    <div className="info">
                      <div className="infoHeader">{item.name}</div>
                      <div className="price">${item.price}</div>
                      <div>Quantity: 1</div>
                    </div>
                    <img
                      onClick={() =>
                        dispatch({ type: "REMOVE_FROM_CART", payload: item.id })
                      }
                      className="bin pointer-on-hover"
                      src={bin}
                      alt="delete"
                    />
                  </div>
                ))}
              </div>
              <hr />
              <div className="initialOrderSummary orderSummaries">
                <div className="header">Order Summary</div>
                <div className="price">${total}</div>
              </div>
              <div className="additionalService orderSummaries">
                <div className="header">Additional Service</div>
                <div className="price">${0}</div>
              </div>
              <div className="finalOrderSummary orderSummaries">
                <div className="header">Order Summary</div>
                <div className="price">${total}</div>
              </div>
            </div>
          </Col>
          <Col className="paymentInformation m-4">
            <div className="header">Payment Information</div>
            <div className="card">
              {!clientToken && (
                <div className="d-flex flex-column align-items-center m-2">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="m-2">Connecting to Payment</div>
                </div>
              )}
              {!isValid && (
                <div className="text-danger"> Fill the shipping details to continue with payment</div>
              )}
              {clientToken && (
                <CheckoutForm
                  active={!paymentProcessing && isValid}
                  paymentOptions={{
                    clientToken,
                    price: total,
                  }}
                  onSuccessfulPayment={onSuccess}
                />
              )}
            </div>
          </Col>
        </Row>
        <div className="m-4">
          <div className="header">Shipping Details</div>
          <div className="card">
            <div className="row">
              <Col sm={12} md={6}>
                <div className="formGroup">
                  <div className="formLabel">Name</div>
                  <input
                    {...register("name", { required: true, minLength: 3 })}
                    className="formInput"
                    placeholder="Enter your name"
                  />
                </div>
                <div className="formGroup">
                  <div className="formLabel">Phone Number</div>
                  <input
                    {...register("phone", { required: true, minLength: 10 })}
                    className="formInput"
                    placeholder="Enter your phone number"
                  />
                </div>
                <div className="formGroup">
                  <div className="formLabel">Alternate Phone Number</div>
                  <input
                    {...register("alternatePhone", {
                      required: true,
                      minLength: 10,
                    })}
                    className="formInput"
                    placeholder="Enter your alternate phone number"
                  />
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div className="formGroup">
                  <div className="formLabel">Address</div>
                  <textarea
                    {...register("address", { required: true, minLength: 3 })}
                    rows={4}
                    className="formInput"
                    placeholder="Enter your address"
                  />
                </div>
                <div className="formGroup">
                  <div className="formLabel">Shipping address</div>
                  <input
                    {...register("shippingAddress", {
                      required: true,
                      minLength: 3,
                    })}
                    className="formInput"
                    placeholder="Enter your shipping address"
                  />
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
